var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { loading: _vm.isLoading, flat: "" } },
    [
      !_vm.isLoading
        ? _c(
            "v-expansion-panels",
            _vm._l(_vm.feedItems, function (candidateJobListing, index) {
              return _c(
                "v-expansion-panel",
                { key: index },
                [
                  _c(
                    "v-expansion-panel-header",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            "data-cy": `JobListing-link-${index}`,
                            to: {
                              name: "clients-ViewJobListing",
                              query: {
                                jobListingId: candidateJobListing.jobListingId,
                              },
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(candidateJobListing.jobListing.name) +
                              " (" +
                              _vm._s(candidateJobListing.stage.name) +
                              ") "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-list",
                        { attrs: { "two-line": "" } },
                        [
                          _vm._l(
                            candidateJobListing.comments,
                            function (item, index) {
                              return [
                                _c(
                                  "v-list-item",
                                  { key: index },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "text-left" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.subtitle) + " "
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-left d-block mt-3",
                                            staticStyle: {
                                              "font-size": "smaller",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item.createdBy
                                                ),
                                              },
                                            }),
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.mdiClockOutline)
                                                ),
                                              ]
                                            ),
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(item.created),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-list-item-action",
                                      [
                                        item.isRead ||
                                        item.messageType === "comment"
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "grey lighten-1",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.icon) + " "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "yellow darken-3",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.markAsRead(item)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.icon) + " "
                                                ),
                                              ]
                                            ),
                                        _c("v-list-item-action-text"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }