var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toggle-control" },
    [
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("div", { staticClass: "text-h6" }, [_vm._v(_vm._s(_vm.title))]),
        _c("div", [_vm._v(_vm._s(_vm.description))]),
      ]),
      _c("v-switch", {
        attrs: { color: _vm.color, disabled: _vm.disabled },
        on: { change: _vm.onChange },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }