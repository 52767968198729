/* eslint-disable class-methods-use-this */
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { B3Propagator } from '@opentelemetry/propagator-b3';
import { Resource } from '@opentelemetry/resources';
import { SimpleSpanProcessor, BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { ATTR_SERVICE_NAME, ATTR_SERVICE_VERSION } from '@opentelemetry/semantic-conventions';
import { useStore } from './use';
import pkg from '../package.json';

const baseUrl = process.env.VUE_APP_OTELURL ?? 'http://localhost:4000';
const store = useStore();
const isProduction = process.env.NODE_ENV === 'production';

const getUsername = () => store.getters['auth/getUsername'] ?? 'anonymous';

class CustomSpanProcessor {
  onStart(span) {
    span.setAttribute('username', getUsername());
  }

  onEnd(span) {
    span.setAttribute('username', getUsername());
    // Additional logic can be added here if needed
  }

  shutdown() {
    return Promise.resolve();
  }

  forceFlush() {
    return Promise.resolve();
  }
}

const exporter = new OTLPTraceExporter({
  url: `${baseUrl}/v1/traces`,
  concurrencyLimit: 10,
});

const provider = new WebTracerProvider({
  resource: new Resource({
    [ATTR_SERVICE_NAME]: pkg.name,
    [ATTR_SERVICE_VERSION]: pkg.version,
  }),
});
const customSpanProcessor = new CustomSpanProcessor();
provider.addSpanProcessor(customSpanProcessor);

if (isProduction) {
  provider.addSpanProcessor(new BatchSpanProcessor(exporter));
} else {
  provider.addSpanProcessor(new SimpleSpanProcessor(exporter));
}
provider.register({
  contextManager: new ZoneContextManager(),
  propagator: new B3Propagator(),
});
const addCustomAttributesToSpan = (span) => {
  span.setAttribute('username', getUsername());
};
const addCustomAttributesToResourceFetchSpan = (span, resource) => {
  span.setAttribute('username', getUsername());
  span.setAttribute('deliveryType', resource.deliveryType);
  span.setAttribute('initiatorType', resource.initiatorType);
  span.setAttribute('resource.tcp.duration_ms', resource.connectEnd - resource.connectStart);
};

const addCustomAttributesToDocumentFetch = (span, resource) => {
  span.setAttribute('username', getUsername());
  span.setAttribute('deliveryType', resource.deliveryType);
  span.setAttribute('initiatorType', resource.initiatorType);
  span.setAttribute('resource.tcp.duration_ms', resource.connectEnd - resource.connectStart);
};

registerInstrumentations({
  instrumentations: [
    new XMLHttpRequestInstrumentation({
      ignoreUrls: ['https://m.clarity.ms/collect'],
      ignoreNetworkEvents: false,
      applyCustomAttributesOnSpan: (span, request) => {
        span.setAttribute('username', getUsername());
        span.setAttribute('entryType', request.entryType);
        span.setAttribute('url', request.url);
        span.setAttribute('cache', request.cache);
        span.setAttribute('mode', request.mode);
      },
    }),
    new DocumentLoadInstrumentation({
      ignoreNetworkEvents: false,
      ignorePerformancePaintEvents: false,
      applyCustomAttributesOnSpan: {
        documentLoad: addCustomAttributesToSpan,
        documentFetch: addCustomAttributesToDocumentFetch,
        resourceFetch: addCustomAttributesToResourceFetchSpan,
      },
    }),
  ],
});
