<template>
  <v-row>
    <v-col v-if="chartData.length > 1" :cols="isVertical ? '12' : '6'">
      <div class="text-subtitle-2 text-center">
        Reasons for Stopping
      </div>
      <GChart
        type="BarChart"
        :data="chartData"
        :options="chartOptions"
        :resizeDebounce="500"
      />
    </v-col>
    <v-col v-if="!hideExplanations && chartData2.length > 1" :cols="isVertical ? '12' : '6'">
      <div class="text-subtitle-2 text-center">
        Explanations
      </div>
      <GChart
        type="BarChart"
        :data="chartData2"
        :options="chartOptions2"
        :resizeDebounce="500"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { GChart } from 'vue-google-charts/legacy';
import { sortBy } from 'lodash';
import { useTrace } from '@/use';

const trace = useTrace();

export default {
  name: 'GraphJobListingIssues',
  props: {
    jobListingId: {
      type: String,
      default: 'job',
    },
    hideExplanations: {
      type: Boolean,
      default: false,
    },
    isVertical: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 330,
    },
  },
  components: {
    GChart,
  },
  data() {
    return {
      explanations: [],
      reasons: [],
      chartOptions: {
        isStacked: true,
        colors: ['#1e0ca9'],
        legend: { position: 'none' },
        height: this.height,
        hAxis: {
          showTextEvery: 1,
        },
        vAxis: {
          showTextEvery: 1,
        },
      },
      chartOptions2: {
        isStacked: true,
        legend: { position: 'none' },
        colors: ['#1e0ca9'],
        height: this.height,
        chartArea: {
          left: 250,
          height: '100%',
        },
        hAxis: {
          showTextEvery: 1,
        },
        vAxis: {
          showTextEvery: 1,
        },
      },
    };
  },
  computed: {
    ...mapGetters('stages', ['orderedStages']),
    chartData() {
      const chartData = [['Reason', 'Count', { role: 'annotation' }]];
      this.reasons.forEach((item) => {
        chartData.push([item.name, item.count, item.count.toString()]);
      });
      return chartData;
    },
    chartData2() {
      const chartData = [['Explanation', 'Count']];
      const sortedExplanations = sortBy(this.explanations, 'count');
      sortedExplanations.forEach((item) => {
        chartData.push([item.name, item.count]);
      });
      const emptyExplanations = this.explanations.filter((item) => item.count === 0);
      emptyExplanations.forEach((item) => {
        chartData.push([item.name, item.count]);
      });
      return chartData;
    },
  },
  watch: {
    jobListingId: {
      immediate: true,
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  async mounted() {
    await this.refresh();
    this.$root.$on('update:jobListingCandidate', this.refresh);
  },
  destroyed() {
    this.$root.$off('update:jobListingCandidate', this.refresh);
  },
  methods: {
    async loadExplanations() {
      const { data } = await this.$http.get(`/api/reports/explanations/${this.jobListingId}`);
      this.explanations = data;
    },
    async loadReasons() {
      const { data } = await this.$http.get(`/api/reports/reasons/${this.jobListingId}`);
      this.reasons = data;
    },
    async refresh() {
      const span = trace.startSpan('GraphJobListing.refresh');
      try {
        await Promise.all([
          (async () => {
            await this.loadExplanations();
          })(),
          (async () => {
            await this.loadReasons();
          })(),
        ]);
        span.setAttribute('reasons', this.reasons.length);
        span.setAttribute('explanations', this.explanations.length);
      } catch (error) {
        span.setStatus({ code: 2, message: 'Error loading issues graph' });
        span.recordException(error);
        this.$root.$emit('toast:error', 'Error loading issues graph');
      } finally {
        span.end();
      }
    },
  },
};
</script>
