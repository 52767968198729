export default {
  organization: (state) => state.organization,
  getOrganization: (state) => state.organization,
  getOrganizationById: (state) => (id) => state.allOrganizations?.find((o) => o.id === id),
  hasMultipleOrgs: (state) => state.organizations?.length > 1 ?? false,
  orgUserRoles: (state) => state.allOrganizations?.map((o) => ({
    orgId: o.id,
    role: 'Users',
  })),
};
