import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('jobListingNotification', 'jobListingNotifications');

const customActions = {
  async LOAD_jobListingNotificationsForEmail({ commit, rootGetters }, { email, jobListingId }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListingNotifications')
      .query({ $filter: `IsDeleted eq false AND Email eq '${email}' AND jobListingId eq ${jobListingId}` });
    commit('SET_jobListingNotifications', data);

    return data;
  },
};

export default { ...actions, ...customActions };
