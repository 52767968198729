<template>
  <v-container fluid>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-card class="mb-10">
      <v-card-title primary-title>
        {{ $$t('title') }}
        <v-spacer />
        <v-checkbox
          label="Favourites"
          :value="filterFavourites"
          @change="onFilterFavouritesChange"
        />
      </v-card-title>
    </v-card>
    <v-data-iterator
      :items="items"
      :items-per-page="4"
      :loading="isLoading"
      :footer-props="{ itemsPerPageOptions: [4, 8, 12, 16, 20] }"
    >
      <template v-slot:default="{ items }">
        <v-row>
          <v-col
            v-for="(item, index) in items"
            :key="index"
            cols="12"
            md="6"
            lg="4"
            xl="3"
          >
            <v-card>
              <ScreenShot :element-id="item.id" />
              <div :id="item.id">
                <div class="text-h5 text-center d-block">
                  {{ item.client?.name }}
                </div>
                <router-link
                  class="text-h5 text-center d-block"
                  :to="{ name: 'clients-ViewJobListing', query: { jobListingId: item.id } }">
                  {{ item.name }}
                </router-link>
                <v-card-text v-if="!isLoading">
                  <v-tabs
                    v-model="item.tab"
                  >
                    <v-tab>
                      Pipeline
                    </v-tab>
                    <v-tab>
                      Active
                    </v-tab>
                    <v-tab>
                      Issues
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items class="mt-5" v-model="item.tab">
                    <v-tab-item>
                      <GraphFunnel
                        :jobListingId="`${item.id}`"
                      />
                    </v-tab-item>
                    <v-tab-item>
                      <GraphJobListingHome :jobListingCandidates="item.jobListingCandidates" />
                    </v-tab-item>
                    <v-tab-item>
                      <GraphJobListingIssues
                        :jobListingId="`${item.id}`" />
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { mdiHome } from '@mdi/js';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import org from '@codehq/aurora-app-core/src/mixins/org';
import ScreenShot from '../components/ScreenShot.vue';
import GraphJobListingHome from '../modules/clients/components/graphs/GraphJobListingHome.vue';
import GraphFunnel from '../modules/clients/components/graphs/GraphFunnel.vue';
import GraphJobListingIssues from '../modules/clients/components/graphs/GraphJobListingIssues.vue';
import refresh from '../mixins/refresh';

export default {
  name: 'Home',
  module: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    GraphFunnel,
    // eslint-disable-next-line vue/no-unused-components
    GraphJobListingHome,
    // eslint-disable-next-line vue/no-unused-components
    GraphJobListingIssues,
    ProgressLinearTimeout,
    // eslint-disable-next-line vue/no-unused-components
    ScreenShot,
  },
  mixins: [loading, org, refresh],
  data() {
    return {
      mdiHome,
      activeJobs: [],
    };
  },
  computed: {
    ...mapGetters('jobListings', ['activeJobListings']),
    ...mapState('app', ['favouritesOnly']),
    ...mapState('auth', ['userId']),
    filterFavourites: {
      get() {
        return this.favouritesOnly;
      },
      set(newValue) {
        this.SET_favouritesOnly(newValue);
      },
    },
    items() {
      if (this.filterFavourites) {
        return this.activeJobs.filter((jobListing) => {
          const identifier = `${this.userId}-${jobListing.id}`;
          return this.$store.getters['favourites/isFavourite']('JobListing', identifier);
        });
      }
      return this.activeJobs;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('jobListings', ['LOAD_jobListingDashboard']),
    ...mapActions('stages', ['LOAD_stages']),
    ...mapMutations('app', ['SET_favouritesOnly']),
    async onFilterFavouritesChange(value) {
      this.activeJobs = [];
      await this.$nextTick();
      this.activeJobs = [...this.activeJobListings];
      this.SET_favouritesOnly(value);
      this.filterFavourites = value;
    },
    async refresh() {
      try {
        this.isLoading = true;
        await Promise.all([
          (async () => {
            await this.LOAD_jobListingDashboard();
            this.activeJobs = [...this.activeJobListings];
          })(),
          (async () => {
            await this.LOAD_stages();
          })(),
        ]);
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Timed out reaching the server, please refresh.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
