<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <a @click="openDialog" @keydown="openDialog" v-bind="on">
        {{ CandidateProp?.name }}
      </a>
    </template>
    <v-card :loading="isLoading">
      <v-card-title>
        <span class="headline">{{ CandidateProp?.name }}</span>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="editCandidate">
        <FormCandidate
          :Candidate="editCandidate"
          :isSaving="isSaving"
          @save="save"
          @cancel="cancel" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiClose } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { useTrace } from '@/use';
import FormCandidate from './FormCandidate.vue';

const trace = useTrace();

export default {
  name: 'CandidateModal',
  components: {
    FormCandidate,
  },
  mixins: [loading],
  props: {
    CandidateProp: {
      type: Object,
      required: true,
    },
  },
  module: 'Candidates',
  data() {
    return {
      dialog: false,
      /**
      * Get/Set editing Candidate. Default to {}.
      */
      editCandidate: undefined,
      mdiClose,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('candidates', ['candidates', 'candidate']),
    ...mapState('candidates', ['industries', 'industries']),
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('candidates', ['LOAD_candidate', 'CREATE_candidate', 'UPDATE_candidate']),
    ...mapActions('industries', ['LOAD_industries']),
    async openDialog() {
      this.dialog = true;
      this.isLoading = true;
      const span = trace.startSpan('CandidateModal.openDialog');
      span.setAttribute('candidateId', this.CandidateProp.id);
      try {
        await Promise.all([
          (async () => {
            this.editCandidate = await this.LOAD_candidate(this.CandidateProp.id);
          })(),
          (async () => {
            await this.LOAD_industries();
          })(),
        ]);
        span.setAttribute('candidate', this.editCandidate);
        span.setAttribute('industries', this.industries);
      } catch (error) {
        span.setStatus({ code: 2, message: 'Error loading candidate' });
        span.recordException(error);
        this.$root.$emit('toast:error', 'Error loading candidate');
      } finally {
        span.end();

        this.isLoading = false;
      }
    },
    /** Method used to save the item
     */
    async save(candidate) {
      this.isSaving = true;
      await this.UPDATE_candidate(candidate);
      this.isSaving = false;
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
};
</script>
