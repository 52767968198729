<template>
  <div>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-timeline
      align-top
      dense
      v-else
    >
      <v-timeline-item
        v-for="comment in candidateComments"
        :key="comment.id"
        class="text-left"
        small
      >
        <div>
          <div class="font-weight-normal">
            <strong>{{ comment.createdBy }}</strong> | {{ formatRelative(comment.created) }}
          </div>
          <div>{{ comment.message }}</div>
        </div>
      </v-timeline-item>
    </v-timeline>
    <v-divider />
    <v-textarea
      v-model="message"
      placeholder="Add a comment"
      outlined
      rows="2">
      >
      <template v-slot:append>
        <v-btn
          color="primary"
          @click="addComment"
          :loading="isSaving"
        >
          Add
        </v-btn>
      </template>
    </v-textarea>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTrace } from '@/use';

const trace = useTrace();

dayjs.extend(relativeTime);
export default {
  name: 'CandidateComments',
  components: {
    ProgressLinearTimeout,
  },
  mixins: [loading],
  props: {
    candidateId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      message: '',
      candidateComments: [],
    };
  },
  watch: {
    candidateId() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('candidateComments', [
      'LOAD_candidateComments',
      'CREATE_candidateComment',
    ]),
    formatRelative(dt) {
      return dayjs(dt).fromNow();
    },
    async addComment() {
      this.isSaving = true;
      await this.CREATE_candidateComment({
        candidateId: this.candidateId,
        message: this.message,
      });
      this.message = '';
      this.isSaving = false;
    },
    async refresh() {
      const span = trace.startSpan('CandidateComments.refresh');
      span.setAttribute('candidateId', this.candidateId);
      if (this.candidateId) {
        this.isLoading = true;
        this.candidateComments = await this.LOAD_candidateComments(this.candidateId);
        span.setAttribute('comments', this.candidateComments.length);
        this.isLoading = false;
      }
      span.end();
    },
  },
};
</script>
