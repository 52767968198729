<template>
  <v-container fluid>
    <v-card :loading="isLoading">
      <v-card-title v-if="jobListing" primary-title>
        <Favourite name="JobListing" :identifier="`${userId}-${jobListing.id}`" />
        <div class="text-h4 mr-5">{{ jobListing.client.name }}:</div>
        <div class="text-h4 mr-5">{{ jobListing.name }}</div> ({{ jobListing.status }})
        <v-icon class="ml-5" v-if="!jobListing.isActive" title="Archived">{{ mdiArchive }}</v-icon>
        <v-spacer />
        <v-text-field
          dense
          clearable
          v-model="search"
          :append-icon="mdiMagnify"
          label="Search"
          single-line
          @keyup.enter="searchCandidates"
          @click:append="searchCandidates"
          @click:clear="clearSearch"
          hint="Press enter to search or click the search icon"
        />
      </v-card-title>
      <v-card-text v-if="jobListing">
        <span style="display: block">{{ jobListing.description }}</span>
        <v-row dense>
          <v-col cols="6" v-if="!lastTab">
            <GraphJobListing :jobListingId="`${jobListing.id}`" />
          </v-col>
          <v-col cols="4" v-if="!lastTab">
            <GraphFunnel :jobListingId="`${jobListing.id}`" :height="220" />
          </v-col>
          <v-col v-if="lastTab" cols="12">
            <GraphJobListingIssues
              :jobListingId="`${jobListing.id}`"
              :hideExplanations="!lastTab"
              :is-vertical="!lastTab"
            />
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          v-if="(search?.length ?? 0) === 0"
          slider-color="secondary"
          class="mb-5"
        >
          <v-tab v-for="(tab, index) in tabs" :key="index">
            {{ tab }}
          </v-tab>
        </v-tabs>
        <v-row>
          <v-col v-if="jobListing" cols="6" class="d-flex align-center">
            <AddCandidate
              :jobListing="jobListing"
              @selected="addCandidates"
              :candidates="availableCandidates"
              :tab="tab" />
            <div class="mr-2" />
            <CreateToDoTask :job-listing-id="jobListing.id" />
            <div class="mr-2" />
            <DialogListTask :job-listing-id="jobListing.id" />
            <div class="mr-2" />
            <JobListingNotifications
              :job-listing-id="jobListing.id"
              :email="username"
            />
          </v-col>
          <v-col cols="6" v-if="tab === 0" class="text-right">
            <ImportExport
              name="Job Listing - Candidates"
              :url="`api/data/jobListingCandidates(${$route.query.jobListingId})`"
              @refresh="refresh"
            />
          </v-col>
        </v-row>
        <v-col cols="12">
          <JobListingOverview
            :jobListing="jobListing"
            :loading="isLoading"
            :showFilterStage="tab === 0"
            :tab="tab"
            :showReasons="lastTab"
            :lastTab="lastTab"
            :stage="stage"
            :stageId="stage?.id"
            :search="search"
          />
        </v-col>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { mdiArchive, mdiFilePdfBox, mdiMagnify } from '@mdi/js';
import CreateToDoTask from '@/components/CreateToDoTask.vue';
import DialogListTask from '@/components/DialogListTask.vue';
import ImportExport from '@/components/ImportExport.vue';
import GraphFunnel from '../components/graphs/GraphFunnel.vue';
import GraphJobListingIssues from '../components/graphs/GraphJobListingIssues.vue';
import AddCandidate from '../components/AddCandidate.vue';
import GraphJobListing from '../components/graphs/GraphJobListing.vue';
import JobListingOverview from '../components/JobListingOverview.vue';
import JobListingNotifications from '../components/JobListingNotifications.vue';

export default {
  name: 'ViewJobListing',
  components: {
    AddCandidate,
    CreateToDoTask,
    DialogListTask,
    GraphJobListing,
    GraphJobListingIssues,
    GraphFunnel,
    JobListingOverview,
    JobListingNotifications,
    ImportExport,
  },
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.jobListings',
        to: { name: 'clients-ListJobListing' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      mdiArchive,
      mdiFilePdfBox,
      mdiMagnify,
      jobListing: undefined,
      jobListingCandidates: [],
      visibleCandidates: [],
      search: '',
    };
  },
  computed: {
    ...mapState('app', ['jobListingTab']),
    ...mapState('auth', ['userId', 'username']),
    ...mapState('candidates', ['candidates']),
    ...mapState('stages', ['stages']),
    ...mapGetters('stages', ['orderedStages']),
    /**
     * Computed property for the tab vuex property
     */
    tab: {
      get() {
        return this.jobListingTab;
      },
      set(newValue) {
        this.SET_jobListingTab(newValue);
      },
    },
    selectedTab() {
      return this.tabs[this.tab];
    },
    availableCandidates() {
      return this.candidates
        .filter((candidate) => {
          const jobListingCandidate = this.jobListingCandidates.find(
            (j) => j.candidateId === candidate.id,
          );
          return !jobListingCandidate;
        })
        .filter((candidate) => candidate.name);
    },
    stage() {
      return this.orderedStages[this.tab - 1];
    },
    lastTab() {
      return this.tab === this.tabs.length - 1;
    },
    tabs() {
      const { orderedStages } = this;
      return ['Overview', ...orderedStages.map((stage) => stage.name), 'Stopped'];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('candidates', ['LOAD_candidatesMin']),
    ...mapActions('industries', ['LOAD_industries']),
    ...mapActions('jobListings', ['LOAD_jobListingExpanded']),
    ...mapActions('explanations', ['LOAD_explanations']),
    ...mapActions('stages', ['LOAD_stages']),
    ...mapActions('tasks', ['LOAD_tasks']),
    ...mapActions('reasons', ['LOAD_reasons']),
    ...mapActions('twilioNumbers', ['LOAD_twilioNumbers']),
    ...mapActions('documentTemplates', ['LOAD_documentTemplates']),
    ...mapActions('jobListingCandidates', [
      'LOAD_jobListingCandidatesByJobListingId',
      'CREATE_jobListingCandidate',
      'UPDATE_jobListingCandidate',
    ]),
    ...mapMutations('jobListingCandidates', ['SET_jobListingCandidates']),
    ...mapMutations('app', ['SET_jobListingTab']),
    async addCandidates(candidates) {
      candidates.forEach(async (candidate) => {
        await this.CREATE_jobListingCandidate({
          candidateId: candidate,
          jobListingId: this.$route.query.jobListingId,
          stageId: this.stages[0].id,
        });
        await this.refresh();
      });
    },
    clearSearch() {
      this.search = '';
      this.setVisibleCandidates();
    },
    searchCandidates() {
      this.$log.debug('searchCandidates', this.search);
      if (this.search === '') {
        this.setVisibleCandidates();
      } else {
        this.visibleCandidates = this.jobListingCandidates?.filter(
          (jobListingCandidate) => {
            const candidate = this.candidates.find(
              (c) => c.id === jobListingCandidate.candidateId,
            );
            if (!candidate) {
              return false;
            }
            return candidate.name?.toLowerCase().includes(this.search?.toLowerCase());
          },
        );
      }
    },
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.LOAD_stages();
        })(),
        (async () => {
          await this.LOAD_documentTemplates();
        })(),
        (async () => {
          await this.LOAD_tasks();
        })(),
        (async () => {
          await this.LOAD_explanations();
        })(),
        (async () => {
          await this.LOAD_reasons();
        })(),
        (async () => {
          await this.LOAD_candidatesMin();
        })(),
        (async () => {
          await this.LOAD_twilioNumbers();
        })(),
        (async () => {
          await this.LOAD_industries();
        })(),
        (async () => {
          this.jobListing = await this.LOAD_jobListingExpanded(this.$route.query.jobListingId);
        })(),
      ]);
      this.isLoading = false;
    },
  },
};
</script>
