var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "a",
                _vm._b(
                  { on: { click: _vm.openDialog, keydown: _vm.openDialog } },
                  "a",
                  on,
                  false
                ),
                [_vm._v(" " + _vm._s(_vm.CandidateProp?.name) + " ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.isLoading } },
        [
          _c(
            "v-card-title",
            [
              _c("span", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.CandidateProp?.name)),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              ),
            ],
            1
          ),
          _vm.editCandidate
            ? _c(
                "v-card-text",
                [
                  _c("FormCandidate", {
                    attrs: {
                      Candidate: _vm.editCandidate,
                      isSaving: _vm.isSaving,
                    },
                    on: { save: _vm.save, cancel: _vm.cancel },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }