<template>
  <div>
    <v-file-input
      v-model="file"
      label="Click here to upload a document"
      :loading="isLoading"
      @change="fileChanged"
      accept="*"
      :error-messages="errors"
    />
    <v-card
      data-cy="File-Upload"
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      :class="{ 'grey lighten-2': dragover }"
    >
      <v-card-text>
        <v-row class="d-flex flex-column" dense align="center" justify="center">
          <v-progress-circular v-if="isLoading" indeterminate color="primary" />
          <v-icon v-else :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
            {{ mdiCloudUpload }}
          </v-icon>
          <p>
            {{ hint }}
          </p>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiArrowUpBoldCircleOutline, mdiCloudUpload, mdiFileUploadOutline } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  name: 'FileUpload',
  mixins: [loading],
  props: {
    /** Set the Mimetype accept. Defaults to imaage/*.
    */
    accept: {
      type: String,
      default: 'application/*',
    },
    /** Error message for max file size. Defaults to File is larger than the limit of 20Mb.
    */
    errorFileSize: {
      type: String,
      default: 'File is larger than the limit of 20Mb',
    },
    hint: {
      type: String,
      default: 'Drag and drop a document here',
    },
    /** Set the icon. Defaults to mdi-camera.
    */
    icon: {
      type: String,
      default: 'mdi-camera',
    },
    /** Set the label. Defaults to image.
    */
    label: {
      type: String,
      default: 'Upload a File',
    },
    /** Max file size. Defaults to 20000000 (20Mb).
    */
    maxFileSize: {
      type: Number,
      default: 20000000,
    },
  },
  data() {
    return {
      mdiArrowUpBoldCircleOutline,
      mdiCloudUpload,
      mdiFileUploadOutline,
      errors: [],
      file: null,
      dragover: false,
      uploadedFiles: [],
    };
  },
  methods: {
    async CREATE_userFile(file) {
      const formData = new FormData();

      formData.append('file', file);
      const response = await fetch(`${baseUrl}/auroraApi/Files`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      return result;
    },
    fileChanged(file) {
      if (!file) {
        return;
      }
      this.errors = [];
      if (file?.size > this.maxFileSize) {
        this.errors.push(this.errorFileSize);
      } else {
        this.upload();
      }
    },
    async upload() {
      const { file } = this;
      this.isLoading = true;
      const result = await this.CREATE_userFile(file);
      const url = `${baseUrl}/auroraApi/files/${result.id}`;
      this.$emit('input', {
        uploadedFile: result,
        url,
      });
      this.$emit('url', url);
      this.isLoading = false;
      this.$root.$emit('toast:notify', 'File uploaded successfully');
      this.file = undefined;
    },
    async onDrop(e) {
      this.dragover = false;
      this.isLoading = true;
      const result = await this.CREATE_userFile(e.dataTransfer.files[0]);
      const url = `${baseUrl}/auroraApi/files/${result.id}`;
      this.$emit('input', {
        uploadedFile: result,
        url,
      });
      this.$emit('url', url);
      this.isLoading = false;
      this.dragover = true;
      this.$root.$emit('toast:notify', 'File uploaded successfully');
    },
  },
};
</script>
