import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('candidateComment', 'candidateComments');

const customActions = {
  async LOAD_candidateComments({ commit, rootGetters }, candidateId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('candidateComments')
      .query({ $filter: `IsDeleted eq false AND CandidateId eq ${candidateId}` });
    commit('SET_candidateComments', data);
    return data;
  },
};

export default { ...actions, ...customActions };
