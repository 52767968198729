<template>
  <v-container fill-height>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-layout v-else justify-center align-start>
      <v-card class="fill-width">
        <div class="d-flex justify-space-between">
          <div>
            <v-card-title>
              Candidate Document Upload
            </v-card-title>
            <v-card-subtitle>{{ welcomeMessage }}</v-card-subtitle>
            <v-card-text>
              <p v-html="gdprMessage" />
            </v-card-text>
          </div>
          <img src="../assets/numaH_Logo_New.png" alt="logo" style="height: 128px; width: 100px; margin-right: 10px" />
        </div>
        <v-card-text>
          <v-alert v-if="isSuccess" type="success" dismissible>
            Your document has been uploaded successfully.
          </v-alert>
          <v-row v-if="!uploadedFileUrl">
            <v-col cols="12">
              <FileUpload
                :accept="documentAccept"
                label="Upload Document"
                :icon="mdiFileDocumentOutline"
                @input="uploadFile"
              />
            </v-col>
            <v-col v-if="uploadedFiles.length > 0" cols="12">
              Thank for uploading:
              <v-list>
                <v-list-item
                  v-for="file in uploadedFiles"
                  :key="file.fileName"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ file.fileName }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <p v-else>
            <b>Thank you for uploading your document.</b>
          </p>
          <details class="mt-5">
            <summary class="text-subtitle-1">Click here for an in-depth look at our data protection policy.</summary>
            <div v-html="moreMessage" class="animate-expand mt-2" />
          </details>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiFileDocumentOutline, mdiTrashCanOutline } from '@mdi/js';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FileUpload from '@/components/FileUploadPublic.vue';

export default {
  name: 'CandidateUpload',
  components: {
    FileUpload,
    ProgressLinearTimeout,
  },
  mixins: [loading],
  data() {
    return {
      applicationSettings: [],
      mdiFileDocumentOutline,
      mdiTrashCanOutline,
      documentAccept: 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      documents: [{}],
      language: 'English',
      localData: '',
      uploadedFiles: [],
      documentType: 'CV',
      uploadedFileUrl: undefined,
      isSuccess: false,
    };
  },
  computed: {
    welcomeMessage() {
      const result = this.applicationSettings.find((setting) => setting.name === 'welcomeMessage')?.value ?? '';
      return result.replace('{CandidateName}', this.$route.query.candidateName);
    },
    gdprMessage() {
      return this.applicationSettings.find((setting) => setting.name === 'gdprMessage')?.value ?? '';
    },
    moreMessage() {
      return this.applicationSettings.find((setting) => setting.name === 'moreMessage')?.value ?? '';
    },
  },
  async mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('applicationSettings', ['LOAD_applicationSettingsByNameSpace']),
    async refresh() {
      this.isLoading = true;
      this.applicationSettings = await this.LOAD_applicationSettingsByNameSpace('candidate.upload.settings');
      this.isLoading = false;
    },
    toggleExpanded() {
      this.showExpanded = !this.showExpanded;
    },
    async uploadFile(file) {
      const { candidateId } = this.$route.query;
      const { jobListingId } = this.$route.query;
      const { language } = this;
      const { url } = file;
      const { fileName } = file.uploadedFile;
      this.isSuccess = false;

      const body = {
        cvUrl: url,
        fileName,
        language,
        jobListingId,
      };
      await fetch(`${process.env.VUE_APP_APIURL}/odata/candidates(${candidateId})/UploadDocument`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      this.isSuccess = true;
      this.uploadedFiles.push({
        fileName,
        url,
      });
    },
  },
};
</script>
<style>
.animate-slide-in {
  animation: slide-in 0.5s ease-out;
}

@keyframes slide-in {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-expand {
  animation: expand 1.5s ease-out;
}

@keyframes expand {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
</style>
