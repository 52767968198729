<template>
  <v-card class="mx-auto" :loading="isLoading" flat>
    <v-expansion-panels v-if="!isLoading">
      <v-expansion-panel
        v-for="(candidateJobListing, index) in feedItems"
        :key="index">
        <v-expansion-panel-header>
          <router-link
            :data-cy="`JobListing-link-${index}`"
            :to="{
              name: 'clients-ViewJobListing',
              query: {
                jobListingId: candidateJobListing.jobListingId,
              },
            }"
          >
            {{ candidateJobListing.jobListing.name }} ({{ candidateJobListing.stage.name }})
          </router-link>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list two-line>
            <template v-for="(item, index) in candidateJobListing.comments">
              <v-list-item :key="index">
                <v-list-item-content class="text-left">
                  {{ item.subtitle }}
                  <div class="text-left d-block mt-3" style="font-size: smaller;">
                    <span v-html="item.createdBy" />
                    <v-icon small>{{ mdiClockOutline }}</v-icon>
                    <span v-html="item.created" />
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    v-if="item.isRead || item.messageType === 'comment'"
                    color="grey lighten-1"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <v-icon
                    v-else
                    color="yellow darken-3"
                    @click="markAsRead(item)"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <v-list-item-action-text />
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex';
import {
  mdiComment,
  mdiEmail,
  mdiMessageProcessing,
  mdiWhatsapp,
  mdiPhone,
  mdiClockOutline,
} from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTrace } from '@/use';

const trace = useTrace();

dayjs.extend(relativeTime);

export default {
  name: 'JobListingCandidateFeed',
  mixins: [loading],
  props: {
    candidateId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      mdiClockOutline,
      mdiMessageProcessing,
      mdiWhatsapp,
      mdiPhone,
      mdiComment,
      mdiEmail,
      messages: [],
      candidateJobListings: [],
    };
  },
  computed: {
    feedItems() {
      return this.candidateJobListings.map((candidateJobListing) => ({
        jobListing: candidateJobListing.jobListing,
        stage: candidateJobListing.stage,
        comments: candidateJobListing.comments.map((comment) => this.formatComment(comment)),
        messages: candidateJobListing.messages.map((message) => this.formatMessage(message)),
      }));
    },
  },
  watch: {
    candidateId() {
      this.refresh();
    },
    jobListingCandidate() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('jobListingCandidates', [
      'LOAD_jobListingCandidatesByCandidateId',
    ]),
    formatRelative(dt) {
      return dayjs(dt).fromNow();
    },
    formatMessageType(messageType) {
      switch (messageType) {
        case 'email':
          return 'Email';
        case 'text':
          return 'Text';
        case 'whatsApp':
          return 'WhatsApp';
        case 'phoneCall':
          return 'Phone Call';
        default:
          return 'Message';
      }
    },
    formatComment(comment) {
      return {
        icon: mdiComment,
        created: `${dayjs(comment.created).fromNow()}`,
        createdDate: comment.created,
        createdBy: `Comment by <strong>${comment.createdBy}</strong>`,
        subtitle: comment.message,
        messageType: 'comment',
      };
    },
    formatMessage(message) {
      return {
        icon: this.getIcon(message.messageType),
        created: `${this.formatRelative(message.created)}`,
        createdDate: message.created,
        createdBy: `${this.formatMessageType(message.messageType)} by <strong>${message.createdBy}</strong>`,
        title: message.subject,
        subtitle: message.body,
        id: message.id,
        isRead: message.isRead,
        messageType: message.messageType,
      };
    },
    getIcon(messageType) {
      switch (messageType) {
        case 'email':
          return mdiEmail;
        case 'text':
          return mdiMessageProcessing;
        case 'whatsApp':
          return mdiWhatsapp;
        case 'phoneCall':
          return mdiPhone;
        default:
          return mdiEmail;
      }
    },
    async markAsRead(item) {
      this.$log.debug(item);
      await this.UPDATE_message({
        id: item.id,
        isRead: true,
      });
      await this.refresh();
      this.$emit('refresh');
    },
    async refresh() {
      const span = trace.startSpan('CandidateFeed.refresh');
      span.setAttribute('candidateId', this.candidateId);
      if (this.candidateId) {
        this.isLoading = true;
        try {
          this.candidateJobListings = await this.LOAD_jobListingCandidatesByCandidateId(this.candidateId);
          span.setAttribute('candidateJobListings', this.candidateJobListings?.length ?? '0');
        } catch (error) {
          span.setStatus({ code: 2, message: 'Error loading candidate feed' });
          span.recordException(error);
          this.$root.$emit('toast:error', 'Error loading candidate feed');
        } finally {
          this.isLoading = false;
        }
      }
      span.end();
    },
  },
};
</script>
