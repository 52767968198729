import { o } from 'odata';
import { authHub, errorHub } from '@codehq/aurora-app-core/src';
import { useTrace } from '../use';

const trace = useTrace();

const odata = {
  o(rootGetters, token) {
    const span = trace.startSpan('odata.o');
    if (!token) {
      // eslint-disable-next-line no-console
      console.warn('no token found using fallback');
      span.setStatus({ code: 2, message: 'no token found using fallback' });
    }
    const accessToken = token ?? window.localStorage.getItem('access_token');
    span.setAttribute('token-length', accessToken?.length ?? '0');
    const organization = rootGetters['organizations/getOrganization'];
    span.setAttribute('organization', organization?.id ?? 'no organization');
    span.end();
    return o(`${process.env.VUE_APP_APIURL ?? 'http://localhost'}/odata/`, this.getConf(accessToken, organization));
  },
  getConf: (token, organization) => ({
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      organizationid: `${organization?.id}`,
    }),
    // onStart: async (x) => {
    //   const tokenRequest = {
    //     scopes: ['api://302098e9-1ec8-438d-b444-1f65d1352158/BrightFuture.Read Team.ReadBasic.All'],
    //   };
    //   const tokenResponse = await userAgentApplication.acquireTokenSilent(tokenRequest);
    //   console.debug(tokenResponse);
    //   // eslint-disable-next-line no-param-reassign
    //   x.config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
    //   console.debug(x);
    //   return x;
    // },
    onError: (x, e) => {
      if (e.status === 401) {
        authHub.$emit('network:unauthorized', {
          source: 'odata',
          data: e,
        });
      } else {
        errorHub.$emit('network.error', e);
      }
    },
  }),
  parseId: (id) => {
    let i = id;
    if (Number.isNaN(id)) {
      i = `'${id}'`;
    }
    return i;
  },
};
export { odata };

export default {};
