<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-select
          v-if="showLanguage"
          :items="['English', 'French', 'German', 'Italian']"
          v-model="language"
          label="Language"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-if="showLanguage"
          :items="['CV', 'Certificate', 'Other']"
          v-model="documentType"
          label="Language"
        />
      </v-col>
      <v-col cols="6">
        <FileUpload
          :accept="documentAccept"
          :label="label"
          :icon="mdiFileDocumentOutline"
          @input="save"
        />
      </v-col>
    </v-row>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-row v-else>
      <v-col cols="12" class="text-left">
        <table class="table-candidate-documents">
          <tbody>
            <tr v-for="document in documents" :key="document.id">
              <td>{{ document.language }}</td>
              <td>{{ document.documentType }}</td>
              <td>
                <a
                  :href="document.documentUrl"
                  color="primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ document.fileName }}
                </a>
              </td>
              <td>
                <v-icon small color="error" @click="remove(document)">
                  {{ mdiTrashCanOutline }}
                </v-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.table-candidate-documents td {
  padding-right: 10px;
  padding-top: 5px;
}
</style>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiFileDocumentOutline, mdiTrashCanOutline } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import { useTrace } from '@/use';

const trace = useTrace();

export default {
  name: 'CandidateDocuments',
  components: {
    ProgressLinearTimeout,
  },
  mixins: [loading],
  props: {
    candidateId: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'CV Document',
    },
    showLanguage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mdiFileDocumentOutline,
      mdiTrashCanOutline,
      documentAccept: 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*',
      documents: [{}],
      localData: '',
      documentType: 'CV',
    };
  },
  computed: {
    ...mapState('controls', ['documentLanguage']),
    /**
     * Computed property for the language vuex property
     */
    language: {
      get() {
        return this.documentLanguage;
      },
      set(newValue) {
        this.SET_documentLanguage(newValue);
      },
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions(
      'candidateDocuments',
      [
        'CREATE_candidateDocument',
        'DELETE_candidateDocument',
        'LOAD_candidateDocumentsByCandidate',
      ],
    ),
    ...mapMutations('controls', ['SET_documentLanguage']),
    async refresh() {
      const span = trace.startSpan('CandidateDocuments.refresh');
      span.setAttribute('candidateId', this.candidateId);
      this.isLoading = true;
      this.documents = await this.LOAD_candidateDocumentsByCandidate({
        candidateId: this.candidateId,
      });
      span.setAttribute('documents', this.documents.length);
      this.isLoading = false;
      span.end();
    },
    async remove(document) {
      const result = await this.$confirm('Are you sure you want to delete this document?');
      if (result) {
        this.isLoading = true;
        await this.DELETE_candidateDocument(document.id);
        this.isLoading = false;
        await this.refresh();
      }
    },
    async save(data) {
      this.isSaving = true;
      const baseUrl = process.env.VUE_APP_APIURL;
      const document = {
        candidateId: this.candidateId,
        language: this.language,
        documentType: this.documentType,
        documentUrl: `${baseUrl}/auroraApi/files/${data.id}`,
        fileName: data.fileName,
      };
      await this.CREATE_candidateDocument(document);
      this.isSaving = false;
      await this.refresh();
    },
  },
};
</script>
