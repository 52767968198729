<template>
  <div class="toggle-control">
    <div class="d-flex flex-column">
      <div class="text-h6">{{ title }}</div>
      <div>{{ description }}</div>
    </div>
    <v-switch
      v-model="localValue"
      :color="color"
      :disabled="disabled"
      @change="onChange"
    />
  </div>
</template>

<script>
export default {
  name: 'ToggleControl',
  props: {
    /**
     * Holds the value of the switch
     */
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    /**
     * Holds the label of the switch
     */
    description: {
      type: String,
      default: '',
    },
    /**
     * Holds the color of the switch
     */
    color: {
      type: String,
      default: 'primary',
    },
    /**
     * Holds the disabled value of the switch
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
    /**
     * Handles the change event of the switch
     */
    onChange(val) {
      this.$emit('input', val);
    },
  },
};
</script>
<style lang="css" scoped>
.toggle-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  padding: 5px 10px;
  border-radius: 8px;
}

.toggle-control .text-h6 {
  color: black;
}
</style>
