var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "50vw" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", dark: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v(_vm._s(_vm.mdiEmailAlertOutline)),
                  ]),
                  _vm._v(" Notifications "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.isLoading } },
        [
          _c(
            "v-card-title",
            { staticClass: "text-h5", attrs: { "primary-title": "" } },
            [_vm._v(" Job Listing Notifications ")]
          ),
          _c(
            "v-card-text",
            { staticClass: "grid grid-cols-1 gap-1" },
            [
              _c("ToggleControl", {
                attrs: {
                  title: "New Candidate",
                  description: "Notify me whenever a new candidate is added",
                  disabled: _vm.isLoading,
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("newCandidate", $event)
                  },
                },
                model: {
                  value: _vm.newCandidate,
                  callback: function ($$v) {
                    _vm.newCandidate = $$v
                  },
                  expression: "newCandidate",
                },
              }),
              _c("ToggleControl", {
                attrs: {
                  title: "Candidate CV Upload",
                  description: "Notify me whenever a candidate CV is uploaded",
                  disabled: _vm.isLoading,
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("candidateCVUpload", $event)
                  },
                },
                model: {
                  value: _vm.candidateCVUpload,
                  callback: function ($$v) {
                    _vm.candidateCVUpload = $$v
                  },
                  expression: "candidateCVUpload",
                },
              }),
              _c("ToggleControl", {
                attrs: {
                  title: "New Comment",
                  description: "Notify me whenever a new comment is added",
                  disabled: _vm.isLoading,
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("newComment", $event)
                  },
                },
                model: {
                  value: _vm.newComment,
                  callback: function ($$v) {
                    _vm.newComment = $$v
                  },
                  expression: "newComment",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { color: "success" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }