/* eslint-disable vue/max-len */
import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('jobListingCandidate', 'jobListingCandidates');

const customActions = {
  async LOAD_stoppedJobListingCandidatesByJobListingId({ rootGetters }, jobListingId) {
    let expand = 'Candidate($select=id,name,currentEmployer,title,linkedInUrl,createdBy,email,jobListingCandidates,phone,imageUrl,dateOfBirth,location,country;$expand=JobListingCandidates($select=id;$count=true;$filter=IsDeleted eq false))';
    expand += ',Stage($select=name,order)';
    expand += ',Task($select=name,order)';
    expand += ',Comments($count=true;$top=2;$orderby=Created desc)';
    expand += ',Messages($count=true;$filter=IsRead ne true;$top=10)';
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListingCandidates')
      .query({
        $filter: `IsDeleted eq false AND JobListingId eq ${jobListingId} AND IsStop eq true`,
        $expand: expand,
        // $count: 'Comments',
        // eslint-disable-next-line max-len
        $select: 'Id,Candidate,Stage,Task,CandidateId,Created,CreatedBy,Updated,StageId,TaskId,ExplanationId,ReasonId,IsStop,PrintData',
      });

    return data;
  },
  async LOAD_jobListingCandidatesByJobListingIdAndStageId({ rootGetters }, { jobListingId, stageId }) {
    let expand = 'Candidate($select=id,name,currentEmployer,title,linkedInUrl,createdBy,email,jobListingCandidates,phone,imageUrl,dateOfBirth,location,country;$expand=JobListingCandidates($select=id;$count=true;$filter=IsDeleted eq false))';
    expand += ',Stage($select=name,order)';
    expand += ',Task($select=name,order)';
    expand += ',Comments($count=true;$top=2;$orderby=Created desc)';
    expand += ',Messages($count=true;$filter=IsRead ne true;$top=10)';
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListingCandidates')
      .query({
        $filter: `IsDeleted eq false AND JobListingId eq ${jobListingId} AND StageId eq ${stageId} AND IsStop ne true`,
        $expand: expand,
        // $count: 'Comments',
        // eslint-disable-next-line max-len
        $select: 'Id,Candidate,Stage,Task,CandidateId,Created,CreatedBy,Updated,StageId,TaskId,ExplanationId,ReasonId,IsStop,PrintData',
      });

    return data;
  },
  async LOAD_jobListingCandidatesByJobListingId({ rootGetters }, jobListingId) {
    let expand = 'Candidate($select=id,name,currentEmployer,title,linkedInUrl,createdBy,email,jobListingCandidates,phone,imageUrl,dateOfBirth,location,country;$expand=JobListingCandidates($select=id;$count=true;$filter=IsDeleted eq false))';
    expand += ',Stage($select=name,order)';
    expand += ',Task($select=name,order)';
    expand += ',Comments($count=true;$top=2;$orderby=Created desc)';
    expand += ',Messages($count=true;$filter=IsRead ne true;$top=10)';
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListingCandidates')
      .query({
        $filter: `IsDeleted eq false AND JobListingId eq ${jobListingId}`,
        $expand: expand,
        // $count: 'Comments',
        // eslint-disable-next-line max-len
        $select: 'Id,Candidate,Stage,Task,CandidateId,Created,CreatedBy,Updated,StageId,TaskId,ExplanationId,ReasonId,IsStop,PrintData',
      });
    return data;
  },
  async LOAD_jobListingCandidatesByJobListingIdForGraphs({ rootGetters }, jobListingId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListingCandidates')
      .query({
        $filter: `IsDeleted eq false AND JobListingId eq ${jobListingId}`,
        $select: 'stageId,isStop',
      });
    return data;
  },
  async LOAD_jobListingCandidatesByCandidateId({ rootGetters }, candidateId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListingCandidates')
      .query({
        $filter: `IsDeleted eq false AND CandidateId eq ${candidateId}`,
        $expand: 'JobListing($select=id,name),Stage($select=name),Comments,Messages',
        $orderby: 'Created desc',
      });
    return data;
  },
};

export default { ...actions, ...customActions };
