<template>
  <div>
    <ProgressLinearTimeout v-if="isLoading" />
    <h3 v-else class="text-center text--primary">Process Stage</h3>
    <div ref="funnel" :id="`Funnel-${jobListingId}`" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import D3Funnel from 'd3-funnel';
import { useTrace } from '@/use';

const trace = useTrace();

export default {
  name: 'GraphFunnel',
  mixins: [loading],
  components: {
    ProgressLinearTimeout,
  },
  props: {
    jobListingId: {
      type: String,
      default: 'job',
    },
    height: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      jobListingCandidates: [],
      completedTasks: [],
      options: {
        block: {
          dynamicHeight: true,
          minHeight: 15,
          fill: {
            type: 'gradient',
            scale: ['#1e0b4c', '#1e0ca9', '#7080f3', '#9467bd', '#f8cf59', '#2ca02c'],
          },
        },
        chart: {
          height: this.height,
        },
      },
    };
  },
  computed: {
    ...mapGetters('stages', ['orderedStages']),
    candidates() {
      const { orderedStages } = this;
      return this.jobListingCandidates.map((candidate) => {
        const { stageId } = candidate;
        const stage = orderedStages.find((s) => s.id === stageId);
        const stages = orderedStages.filter((s) => s.order <= stage?.order);
        return { ...candidate, stages };
      });
    },
    chartData() {
      const { orderedStages, candidates } = this;
      const result = orderedStages.map((stage) => {
        const { name } = stage;
        const count = candidates.filter((c) => c.stages.includes(stage)).length;
        return {
          value: count,
          fill: '#c33',
          // fill: '#1e0ca9',
          label: name,
        };
      });
      return result;
    },
  },
  watch: {
    chartData() {
      this.draw();
    },
    jobListingId() {
      this.refresh();
    },
  },
  destroyed() {
    this.$root.$off('update:jobListingCandidate', this.refresh);
    const el = document.getElementById(`Funnel-${this.jobListingId}`);
    if (el) {
      el.innerHTML = '';
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.refresh();
    this.isLoading = false;
    setTimeout(() => {
      this.draw();
    }, 300);
    this.$root.$on('update:jobListingCandidate', this.refresh);
  },
  methods: {
    draw() {
      const { chartData, options, jobListingId } = this;
      setTimeout(() => {
        try {
          const chart = new D3Funnel(`#Funnel-${jobListingId}`);
          chart.draw(chartData, options);
        } catch (error) {
          this.$log.error(error);
        }
      }, 500);
    },
    async refresh() {
      const span = trace.startSpan('GraphFunnel.refresh');
      try {
        const { data } = await this.$http.get(`/api/reports/dashboard/current/${this.jobListingId}`);
        this.jobListingCandidates = data;
        span.setAttribute('candidates', this.jobListingCandidates.length);
      } catch (error) {
        span.setStatus({ code: 2, message: 'Error loading funnel graph' });
        span.recordException(error);
        this.$root.$emit('toast:error', 'Error loading funnel graph');
      } finally {
        span.end();
      }
    },
  },
};
</script>
