<template>
  <v-dialog v-model="dialog" max-width="50vw">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        color="primary"
        dark
        v-bind="attrs"
      >
        <v-icon class="mr-2">{{ mdiEmailAlertOutline }}</v-icon>
        Notifications
      </v-btn>
    </template>
    <v-card :loading="isLoading">
      <v-card-title primary-title class="text-h5">
        Job Listing Notifications
      </v-card-title>
      <v-card-text class="grid grid-cols-1 gap-1">
        <ToggleControl
          v-model="newCandidate"
          title="New Candidate"
          description="Notify me whenever a new candidate is added"
          :disabled="isLoading"
          @input="onChange('newCandidate', $event)"
        />
        <ToggleControl
          v-model="candidateCVUpload"
          title="Candidate CV Upload"
          description="Notify me whenever a candidate CV is uploaded"
          :disabled="isLoading"
          @input="onChange('candidateCVUpload', $event)"
        />
        <ToggleControl
          v-model="newComment"
          title="New Comment"
          description="Notify me whenever a new comment is added"
          :disabled="isLoading"
          @input="onChange('newComment', $event)"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" class="mr-2" @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiEmailAlertOutline } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { useTrace } from '@/use';
import ToggleControl from '@/components/ToggleControl.vue';

const trace = useTrace();

export default {
  name: 'JobListingNotifications',
  mixins: [loading],
  props: {
    jobListingId: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  components: {
    ToggleControl,
  },
  data() {
    return {
      mdiEmailAlertOutline,
      jobListingNotifications: [],
      dialog: false,
      candidateCVUpload: false,
      newCandidate: false,
      newComment: false,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.refresh();
      }
    },
  },
  methods: {
    ...mapActions('jobListingNotifications', [
      'LOAD_jobListingNotificationsForEmail',
      'CREATE_jobListingNotification',
      'UPDATE_jobListingNotification',
    ]),
    async onChange(key, value) {
      const span = trace.startSpan('JobListingNotifications.onChange');
      span.setAttribute('key', key);
      span.setAttribute('value', value);
      this[key] = value;
      await this.addUpdateJobListingNotification({
        jobListingId: this.jobListingId,
        email: this.email,
        type: key,
        isActive: value,
      });
      span.end();
    },
    applyData(jobListingNotifications) {
      jobListingNotifications.forEach((notification) => {
        this[notification.type] = notification.isActive;
      });
    },
    async addUpdateJobListingNotification(jobListingNotification) {
      const existingNotification = this.jobListingNotifications.find(
        (notification) => notification.type === jobListingNotification.type,
      );
      if (existingNotification) {
        const span = trace.startSpan('JobListingNotifications.update');
        try {
          span.setAttribute('jobListingNotificationId', existingNotification.id);
          span.setAttribute('isActive', jobListingNotification.isActive);
          span.setAttribute('jobListingId', this.jobListingId);
          span.setAttribute('email', this.email);
          await this.UPDATE_jobListingNotification({
            ...existingNotification,
            isActive: jobListingNotification.isActive,
          });
        } catch (error) {
          span.recordException(error);
          span.setStatus({
            code: 2,
            message: error.message,
          });
          this.$root.$emit('toast:error', 'Unable to update notification');
        } finally {
          span.end();
        }
      } else {
        const span = trace.startSpan('JobListingNotifications.create');
        try {
          span.setAttribute('isActive', jobListingNotification.isActive);
          span.setAttribute('jobListingId', this.jobListingId);
          span.setAttribute('email', this.email);
          await this.CREATE_jobListingNotification(jobListingNotification);
        } catch (error) {
          span.recordException(error);
          span.setStatus({
            code: 2,
            message: error.message,
          });
          this.$root.$emit('toast:error', 'Unable to create notification');
        } finally {
          span.end();
        }
      }
      await this.refresh();
    },
    async refresh() {
      const span = trace.startSpan('JobListingNotifications.refresh');
      try {
        this.isLoading = true;
        span.setAttribute('jobListingId', this.jobListingId);
        span.setAttribute('email', this.email);
        this.jobListingNotifications = await this.LOAD_jobListingNotificationsForEmail({
          jobListingId: this.jobListingId,
          email: this.email,
        });
        this.applyData(this.jobListingNotifications);
      } catch (error) {
        span.recordException(error);
        span.setStatus({
          code: 2,
          message: error.message,
        });
        this.$root.$emit('toast:error', 'Unable to load notifications');
      } finally {
        this.isLoading = false;
        span.end();
      }
    },
  },
};
</script>
