var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.items,
      loading: _vm.isLoading,
      "show-expand": "",
      "show-select": "",
      "item-key": "id",
      expanded: _vm.expanded,
      "single-expand": true,
      "sort-by": _vm.sortField,
      "sort-desc": _vm.sortDirection,
      "items-per-page": 25,
      dense: "",
      "footer-props": {
        "items-per-page-options": [25, 50, 100, 250, 500, -1],
      },
    },
    on: {
      "update:expanded": function ($event) {
        _vm.expanded = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-toolbar",
              { attrs: { flat: "" } },
              [
                _c("SendMessages", {
                  attrs: { selected: _vm.selected },
                  on: { email: _vm.sendMails },
                }),
                _c("StopCandidates", {
                  attrs: { selected: _vm.selected },
                  on: {
                    refresh: function ($event) {
                      _vm.selected = []
                      _vm.$emit("refresh")
                    },
                  },
                }),
                _c("UpdateJoblistingCandidates", {
                  attrs: { selected: _vm.selected },
                }),
                _c("v-toolbar-title", [
                  _vm._v("Candidates (" + _vm._s(_vm.items.length) + ")"),
                ]),
                _c("v-spacer"),
                _c("OpenInTeams", { attrs: { "job-listing": _vm.jobListing } }),
                _c("v-checkbox", {
                  staticClass: "mr-2 ml-2 mt-5",
                  attrs: { label: "Favourites", dense: "" },
                  model: {
                    value: _vm.filterFavourites,
                    callback: function ($$v) {
                      _vm.filterFavourites = $$v
                    },
                    expression: "filterFavourites",
                  },
                }),
                _c("v-select", {
                  staticClass: "mr-2 ml-2 mt-5",
                  attrs: {
                    dense: "",
                    clearable: "",
                    items: _vm.users,
                    placeholder: "Created By",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [_c("DisplayName", { attrs: { user: item } })],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "selection",
                      fn: function ({ item }) {
                        return [_c("DisplayName", { attrs: { user: item } })]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.selectedCreatedBy,
                    callback: function ($$v) {
                      _vm.selectedCreatedBy = $$v
                    },
                    expression: "selectedCreatedBy",
                  },
                }),
                !_vm.showFilterStage
                  ? _c("v-select", {
                      staticClass: "mr-2 ml-2 mt-5",
                      attrs: {
                        dense: "",
                        clearable: "",
                        items: _vm.orderedStages,
                        "item-text": "name",
                        "item-value": "id",
                        placeholder: "Stage",
                      },
                      model: {
                        value: _vm.selectedStage,
                        callback: function ($$v) {
                          _vm.selectedStage = $$v
                        },
                        expression: "selectedStage",
                      },
                    })
                  : _vm._e(),
                _vm.getTasksByStageId(_vm.selectedStage ?? _vm.stageId)
                  ?.length > 0
                  ? _c("v-select", {
                      staticClass: "mr-2 ml-2 mt-5",
                      attrs: {
                        dense: "",
                        clearable: "",
                        items: _vm.getTasksByStageId(
                          _vm.selectedStage ?? _vm.stageId
                        ),
                        "item-text": "name",
                        "item-value": "id",
                        placeholder: "Task",
                      },
                      model: {
                        value: _vm.selectedTask,
                        callback: function ($$v) {
                          _vm.selectedTask = $$v
                        },
                        expression: "selectedTask",
                      },
                    })
                  : _vm._e(),
                _c("v-select", {
                  staticClass: "mr-2 ml-2 mt-5",
                  attrs: {
                    label: "Sort By",
                    items: ["Updated", "Name", "Created"],
                  },
                  model: {
                    value: _vm.sortBy,
                    callback: function ($$v) {
                      _vm.sortBy = $$v
                    },
                    expression: "sortBy",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "expanded-item",
        fn: function ({ item, index }) {
          return [
            _c(
              "td",
              { staticClass: "expanded-column", attrs: { colspan: "4" } },
              [
                _c(
                  "v-card",
                  { staticClass: "elevation-1 mb-5 mt-5" },
                  [
                    _c("v-card-title", { staticClass: "text-h6" }, [
                      _vm._v("Candidate Info"),
                    ]),
                    _c(
                      "v-card-text",
                      [
                        _c("ModalInlineTextField", {
                          attrs: { label: "Email" },
                          on: {
                            input: function ($event) {
                              return _vm.updateCandidate(
                                item.candidate,
                                "email",
                                $event
                              )
                            },
                          },
                          model: {
                            value: item.candidate.email,
                            callback: function ($$v) {
                              _vm.$set(item.candidate, "email", $$v)
                            },
                            expression: "item.candidate.email",
                          },
                        }),
                        _c("ModalInlineTextField", {
                          attrs: { label: "Phone" },
                          on: {
                            input: function ($event) {
                              return _vm.updateCandidate(
                                item.candidate,
                                "phone",
                                $event
                              )
                            },
                          },
                          model: {
                            value: item.candidate.phone,
                            callback: function ($$v) {
                              _vm.$set(item.candidate, "phone", $$v)
                            },
                            expression: "item.candidate.phone",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card",
                  { staticClass: "elevation-1 mb-5 mt-5" },
                  [
                    _c("v-card-title", { staticClass: "text-h6" }, [
                      _vm._v("Checklist"),
                    ]),
                    _c(
                      "v-card-actions",
                      [
                        _c("JobListingCandidateChecklists", {
                          attrs: { jobListingCandidate: item },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card",
                  { staticClass: "elevation-1 mb-5" },
                  [
                    _c("v-card-title", { staticClass: "text-h6" }, [
                      _vm._v("History"),
                    ]),
                    _c(
                      "v-card-actions",
                      [
                        _c("JobListingCandidateHistory", {
                          attrs: { jobListingCandidate: item },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "td",
              { staticClass: "expanded-column", attrs: { colspan: "5" } },
              [
                _c(
                  "v-card",
                  { staticClass: "elevation-1 mb-5 mt-5" },
                  [
                    _c(
                      "v-card-title",
                      {
                        staticClass:
                          "text-h6 d-flex justify-space-between pa-2",
                      },
                      [
                        _c("span", [_vm._v(" Feed ")]),
                        _c("ImportMail", {
                          attrs: { jobListingCandidate: item },
                          on: { refresh: _vm.refresh },
                        }),
                      ],
                      1
                    ),
                    _c("JobListingCandidateFeed", {
                      attrs: { jobListingCandidate: item },
                      on: { refresh: _vm.refresh },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-card",
                  { staticClass: "mb-5", attrs: { color: "warning" } },
                  [
                    _c("v-card-title", [
                      _c("h4", { staticClass: "headline text--white" }, [
                        _vm._v(" Danger Zone "),
                      ]),
                    ]),
                    _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "error",
                              "data-cy": `JobListingOverview-action-${index}`,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.remove(item)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(_vm._s(_vm.mdiTrashCan)),
                            ]),
                            _vm._v(" Remove Candidate from Project "),
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _c("MoveCandidate", {
                          attrs: { jobListingCandidate: item },
                          on: { refresh: _vm.refresh },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "item.candidate.name",
        fn: function ({ item }) {
          return [
            item.candidate
              ? _c("CandidateModal", {
                  attrs: { CandidateProp: item.candidate },
                })
              : _vm._e(),
            item.candidate?.jobListingCount > 1
              ? _c("span", [
                  _vm._v(" (" + _vm._s(item.candidate?.jobListingCount) + ") "),
                ])
              : _vm._e(),
            item.candidate?.currentEmployer
              ? _c("span", { staticClass: "caption" }, [
                  _vm._v(" " + _vm._s(item.candidate?.currentEmployer) + ": "),
                ])
              : _vm._e(),
            _c("span", { staticClass: "caption" }, [
              _vm._v(_vm._s(item.candidate?.title)),
            ]),
          ]
        },
      },
      {
        key: "item.notifications",
        fn: function ({ item }) {
          return [
            _c("CandidateUploadLink", {
              attrs: {
                "candidate-id": item.candidateId,
                candidate: item.candidate,
                "job-listing-id": _vm.jobListing.id,
              },
            }),
            item.candidate && item.candidate?.linkedInUrl
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      icon: "",
                      href: item.candidate?.linkedInUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.mdiLinkedin))])],
                  1
                )
              : _vm._e(),
            _c(
              "v-badge",
              {
                staticClass: "mr-2",
                attrs: {
                  color: "secondary",
                  content: `${item["comments@odata.count"]}`,
                },
              },
              [_c("v-icon", [_vm._v(_vm._s(_vm.mdiComment))])],
              1
            ),
            _c(
              "v-badge",
              {
                staticClass: "mr-2",
                attrs: {
                  color: "secondary",
                  content: `${item["messages@odata.count"]}`,
                },
              },
              [_c("v-icon", [_vm._v(_vm._s(_vm.mdiEmail))])],
              1
            ),
            item.candidate?.jobListingCount > 1
              ? _c(
                  "v-badge",
                  {
                    attrs: {
                      color: "secondary",
                      content: item.candidate?.jobListingCount,
                    },
                  },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.mdiBriefcaseOutline))])],
                  1
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "item.stop",
        fn: function ({ item, index }) {
          return [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      color: item.isStop ? "red" : "green",
                      outlined: "",
                      small: "",
                      "data-cy": `JobListingOverview-action-stop-${index}`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.swopStop(item)
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v(_vm._s(_vm.mdiCloseOctagonOutline)),
                    ]),
                  ],
                  1
                ),
                _c("ModalPrint", {
                  attrs: {
                    jobListingCandidate: item,
                    jobListing: _vm.jobListing,
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "item.favourite",
        fn: function ({ item }) {
          return [
            _c("Favourite", {
              attrs: {
                name: "JobListingCandidate",
                identifier: `${_vm.userId}-${item.id}`,
              },
            }),
          ]
        },
      },
      {
        key: "item.stage.name",
        fn: function ({ item, index }) {
          return [
            item.isStop === false || !_vm.stageId
              ? _c("v-select", {
                  attrs: {
                    label: "Stage",
                    "data-cy": `JobListingOverview-stage-${index}`,
                    items: _vm.orderedStages,
                    disabled: _vm.orderedStages.length === 0,
                    "item-text": "name",
                    "item-value": "id",
                    loading: _vm.isSaving || _vm.isLoading,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateStage(item, $event)
                    },
                  },
                  model: {
                    value: item.stageId,
                    callback: function ($$v) {
                      _vm.$set(item, "stageId", $$v)
                    },
                    expression: "item.stageId",
                  },
                })
              : _vm._e(),
            item.isStop
              ? _c("v-select", {
                  attrs: {
                    "data-cy": `JobListingOverview-reason-${index}`,
                    label: "Reason",
                    clearable: "",
                    items: _vm.reasons,
                    "item-text": "name",
                    "item-value": "id",
                    loading: _vm.isSaving || _vm.isLoading,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateReason(item, $event)
                    },
                  },
                  model: {
                    value: item.reasonId,
                    callback: function ($$v) {
                      _vm.$set(item, "reasonId", $$v)
                    },
                    expression: "item.reasonId",
                  },
                })
              : _vm._e(),
          ]
        },
      },
      {
        key: "item.task.name",
        fn: function ({ item, index }) {
          return [
            item.isStop === false || !_vm.stageId
              ? _c("v-select", {
                  attrs: {
                    label: "Task",
                    "data-cy": `JobListingOverview-task-${index}`,
                    items: _vm.getItemTask(item),
                    "item-text": "name",
                    "item-value": "id",
                    loading: _vm.isSaving || _vm.isLoading,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateTask(item, $event)
                    },
                  },
                  model: {
                    value: item.taskId,
                    callback: function ($$v) {
                      _vm.$set(item, "taskId", $$v)
                    },
                    expression: "item.taskId",
                  },
                })
              : _vm._e(),
            item.isStop
              ? _c("v-select", {
                  attrs: {
                    label: "Explanation",
                    "data-cy": `JobListingOverview-explanation-${index}`,
                    clearable: "",
                    items: _vm.explanations,
                    "item-text": "name",
                    "item-value": "id",
                    loading: _vm.isSaving || _vm.isLoading,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateExplanation(item, $event)
                    },
                  },
                  model: {
                    value: item.explanationId,
                    callback: function ($$v) {
                      _vm.$set(item, "explanationId", $$v)
                    },
                    expression: "item.explanationId",
                  },
                })
              : _vm._e(),
          ]
        },
      },
      {
        key: "item.reason.name",
        fn: function ({ item, index }) {
          return [
            _c("v-select", {
              attrs: {
                "data-cy": `JobListingOverview-reason-${index}`,
                clearable: "",
                items: _vm.reasons,
                value: item.reasonId,
                "item-text": "name",
                "item-value": "id",
              },
              on: {
                change: function ($event) {
                  return _vm.updateReason(item, $event)
                },
              },
            }),
          ]
        },
      },
      {
        key: "item.initialComments",
        fn: function ({ item }) {
          return [
            _c(
              "span",
              { staticClass: "d-block mt-5" },
              [
                _vm.isMoreThan14Days(item.lastUpdated)
                  ? _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiAlert) + " ")])
                  : _vm._e(),
                _vm._v(
                  " Last Update " +
                    _vm._s(_vm.formatDateTime(item.lastUpdated)) +
                    " "
                ),
              ],
              1
            ),
            _c(
              "v-timeline",
              { attrs: { "align-top": "", dense: "" } },
              _vm._l(_vm.getFeedItems(item), function (feedItem) {
                return _c(
                  "v-timeline-item",
                  {
                    key: feedItem.id,
                    attrs: { small: "", color: "secondary" },
                  },
                  [
                    _c("div", [
                      _c("div", { staticClass: "font-weight-normal" }, [
                        feedItem.messageType === "email"
                          ? _c(
                              "strong",
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(_vm._s(feedItem.icon)),
                                ]),
                                _c("DisplayName", {
                                  attrs: { user: feedItem.from },
                                }),
                                _vm._v(
                                  " | " +
                                    _vm._s(
                                      _vm.formatDateTime(feedItem.createdDate)
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : feedItem.messageType === "whatsApp"
                          ? _c(
                              "strong",
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(_vm._s(feedItem.icon)),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(feedItem.from) +
                                    " | " +
                                    _vm._s(
                                      _vm.formatDateTime(feedItem.createdDate)
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _c(
                              "strong",
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(_vm._s(feedItem.icon)),
                                ]),
                                _c("DisplayName", {
                                  attrs: { user: feedItem.createdBy },
                                }),
                                _vm._v(
                                  " | " +
                                    _vm._s(
                                      _vm.formatDateTime(feedItem.createdDate)
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                      ]),
                      _c("span", { staticClass: "comment" }, [
                        _vm._v(" " + _vm._s(feedItem.subtitle) + " "),
                      ]),
                    ]),
                  ]
                )
              }),
              1
            ),
          ]
        },
      },
      {
        key: "item.explanation.name",
        fn: function ({ item, index }) {
          return [
            _c("v-select", {
              attrs: {
                "data-cy": `JobListingOverview-explanation-${index}`,
                clearable: "",
                items: _vm.explanations,
                value: item.explanationId,
                "item-text": "name",
                "item-value": "id",
              },
              on: {
                change: function ($event) {
                  return _vm.updateExplanation(item, $event)
                },
              },
            }),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }