var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("AzureAuth"),
      _vm.isLoggedIn ? _c("FavouriteService") : _vm._e(),
      _vm.isLoggedIn
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { color: "#FAF5EC", app: "" },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c("v-divider"),
              _c("MenuDrawer"),
              _c("v-divider"),
              _c("v-spacer"),
              _c("div", { staticClass: "logo-left" }, [
                _c("img", {
                  attrs: {
                    src: require("../assets/numaH_Logo_New.png"),
                    contain: "",
                    alt: "logo",
                  },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.isLoggedIn
        ? _c(
            "v-app-bar",
            {
              attrs: {
                color: _vm.$vuetify.theme.dark ? "" : "#FAF5EC",
                dark: "",
                app: "",
              },
            },
            [
              _c("v-app-bar-nav-icon", {
                attrs: { color: "#1e0b4c" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  },
                },
              }),
              _c("v-img", {
                attrs: {
                  src: require("../assets/claim-logo.svg"),
                  contain: "",
                  height: 120,
                  alt: "logo",
                },
              }),
              _vm.isSaving
                ? _c("ProgressCircularTimeout", {
                    attrs: { color: "secondary" },
                  })
                : _vm._e(),
              _vm.isSaving
                ? _c(
                    "div",
                    { staticClass: "text-subtitle-1 ml-2 primary--text" },
                    [_vm._v("Saving...")]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _vm.organization ? _c("OrganizationMenu") : _vm._e(),
              _c(
                "div",
                { staticClass: "mr-2 ml-2 hidden-sm-and-down" },
                [_c("Account")],
                1
              ),
              _c("Feedback"),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: {
                    color: "#1e0b4c",
                    title: "Install Chrome Extension",
                    target: "_blank",
                    rel: "noopener noreferrer",
                    href: "https://chrome.google.com/webstore/detail/bright-future-chrome-exte/pjecpfligbeaonappgcdcncbodbdcble",
                  },
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiPuzzleOutline))])],
                1
              ),
              _vm.isInRole("Administrators")
                ? _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "ml-2",
                                        attrs: { text: "", outlined: "" },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "#1e0b4c" } },
                                      [_vm._v(_vm._s(_vm.mdiCog))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2005406039
                      ),
                    },
                    [_c("AdminMenuDrawer")],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-main",
        [
          _c("Notifications"),
          _c("Breadcrumbs"),
          _c("router-view"),
          _c("ToastSave"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }