<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        icon
      >
        <v-icon>
          {{ mdiFileUploadOutline }}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ label }}
      </v-card-title>
      <v-card-text>
        <ClipboardTextField
          :value="uploadUrl"
          label="Name"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiFileUploadOutline } from '@mdi/js';

export default {
  name: 'CandidateUploadLink',
  props: {
    candidateId: {
      type: String,
      default: null,
    },
    candidate: {
      type: Object,
      default: null,
    },
    jobListingId: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Candidate Upload Link',
    },
  },
  data() {
    return {
      mdiFileUploadOutline,
      dialog: false,
    };
  },
  computed: {
    uploadUrl() {
      const url = `${process.env.VUE_APP_APPURL}/candidate-upload`;
      const params = new URLSearchParams();
      params.append('candidateId', this.candidateId);
      params.append('jobListingId', this.jobListingId);
      params.append('candidateName', this.candidate?.name);
      return `${url}?${params.toString()}`;
    },
  },
};
</script>
